<template>
  <BPageLoader v-if="isLoading" class="flex-grow" />
  <div class="p-6 flex flex-grow flex-col text-text-primary justify-center" v-else>
    <div class="text-center pb-6 flex flex-col items-center justify-center" v-if="isSuccess">
      <SuccessIcon class="mb-12 mt-4 mx-auto" />
      <h4 class="font-semibold text-lg">Congratulations</h4>
      <p class="text-sm max-w-xs text-text-primary my-4 px-8" v-if="$route.query.paymentType === 'DONATION'">
        Your donation to this fundraiser was successful.
      </p>
      <p class="text-sm max-w-xs text-text-primary mt-4 px-7" v-if="$route.query.paymentType === 'PROMOTION'">
        Your promotion payment was successful. Your fundraiser project will be reviewed by the admin in less than 48hrs.
      </p>
      <p class="text-sm max-w-xs text-text-primary my-4" v-if="$route.query.paymentType === 'TOPUP'">
        Your account wallet top up was successful.
      </p>
      <button
        class="bg-btn-bg-primary max-w-xs text-btn-text-primary rounded w-full mt-6 py-4 font-bold text-sm xs:text-base focus:outline-none"
        @click="handleDone"
      >
        Done
      </button>
    </div>
    <div class="text-center flex flex-col items-center" v-else-if="isFailure">
      <FailureIcon class="my-4 mx-auto" />
      <h2 class="text-xl text-text-primary font-semibold my-4">Sorry!</h2>
      <p class="text-xs xs:text-sm text-alternate-3">
        Payment {{ status || 'failed' }}
      </p>
      <button
        class="bg-btn-bg-primary max-w-sm text-btn-text-primary rounded w-full mt-10 py-4 font-bold text-sm xs:text-base focus:outline-none"
        @click="handleDone"
      >
        Ok
      </button>
    </div>
  </div>
</template>

<script>
import { toRefs } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useApi } from '@/cmp-functions/useApi';
import { verifyPayment } from '@/services/api';

export default {
  name: 'FundraiserPaymentStatus',

  setup() {
    const route = useRoute();
    const { type, reference, transaction_id, status, paymentReference } = route.query;
    const [response, checkFundraiser, { setIsFailure }] = useApi(verifyPayment);

    if (type == 1 && transaction_id) {
      checkFundraiser({ type, reference: transaction_id });
    } else if (type == 2) {
      checkFundraiser({ type, reference });
    } else if (type == 3 || type == 5) {
      checkFundraiser({ type, reference: paymentReference });
    } else {
      setIsFailure(true);
    }

    const router = useRouter();
    const handleDone = () => {
      if (type == 1) {
        router.go(-2);
      } else {
        router.go(-1);
      }
    };

    return { ...toRefs(response), status, handleDone };
  },
};
</script>
